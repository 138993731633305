var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "agency-info" }, [
      _c("h5", [_vm._v("Assistenza e info")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n    Telefono: 02.407.413.58\n    "),
        _c("br"),
        _vm._v("\n    Email: assistenza@wichee.com - info@wichee.com\n  "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }