var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fluid-container api" }, [
    _c("p", { staticClass: "text-center" }, [
      _vm._v(
        "\n    CLI version: 3." +
          _vm._s(_vm.cliVer) +
          " :: API version: " +
          _vm._s(_vm.ver) +
          "\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }